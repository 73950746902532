import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    axios.get('/api/comments/')
      .then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the comments!', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/comments/', { name, comment })
      .then(response => {
        setComments([...comments, response.data]);
        setName('');
        setComment('');
      })
      .catch(error => {
        console.error('There was an error posting the comment!', error);
      });
  };

  return (
    <div className="App">
      <h1>Post a Comment</h1>
      <form onSubmit={handleSubmit}>
        <div className='nameDiv'>
          <label>Name:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className='commentDiv'>
          <label>Comment:</label>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} required></textarea>
        </div>
        <button type="submit">Post Comment</button>
      </form>
      <h2>Comments</h2>
      <ul>
        {comments.slice().reverse().map((comment, index) => (
          <div key={index} style={{ margin:'15px 0' }}>
             <div style={{ textAlign: 'left', width:'30%', background:'darkblue', color:'#fff', padding:'5px', borderTopRightRadius:'4px', borderTopLeftRadius:'4px', border:'1px solid darkblue', margin:'0 auto'}}>{comment.name} <br/><small>Fri 19 July 2024</small></div>
             
             <div style={{ textAlign: 'left', width:'30%', margin:'0 auto', border:'1px solid darkblue', padding:'5px', borderBottomRightRadius:'4px', borderBottomLeftRadius:'4px' }}>{comment.comment}</div>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default App;
